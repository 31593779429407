export default (filename, content) => {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });

  saveBlobAsFile(filename, blob);
};

export const saveBlobAsFile = (filename, blob) => {
  if (window.navigator.msSaveBlob) { // IE 10+
    window.navigator.msSaveBlob(blob, filename);

    return;
  }

  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
